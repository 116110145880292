import { createApp } from "vue";

// @ts-ignore
import Bugsnag from "@bugsnag/js";
// @ts-ignore
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import packageJson from "../package.json";

import VueObserveVisibility from "vue-observe-visibility";

Bugsnag.start({
  apiKey: "072083f81c3e1f0c27ab38249a92bc14",
  plugins: [new BugsnagPluginVue()],
  appVersion: packageJson.version,
});

import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import "./functional/prototypes";
// @ts-ignore
import clevertap from "@/services/Clevertap";

const bugsnagVue = Bugsnag.getPlugin("vue");

// @ts-ignore
import InstantSearch from "vue-instantsearch/vue3/es";

import axios from "axios";
axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;
axios.defaults.params = {};
axios.defaults.params["lang"] = "es";
import App from "./App.vue";
import router from "./router";
// @ts-ignore
import $bus from "./functional/events";
import $meta from "./functional/MetadataHandler";

import { i18n } from "./functional/languages";

import "./assets/main.scss";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createMetaManager, plugin as vueMetaPlugin } from "vue-meta";
import { VueReCaptcha } from "vue-recaptcha-v3";

const flixlatinotheme = {
  dark: false,
  colors: {
    background: "#0E1524",
    surface: "#2b374b",
    primary: "#D51F27",
    "primary-darken-1": "#D51F27",
    secondary: "#C3D1FF",
    "secondary-darken-1": "#A0A3BD",
    error: "#B00020",
    info: "#FFFFFF",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
  themes: {
    light: {
      background: "#0E1524",
      surface: "#2b374b",
      primary: "#D51F27",
      "primary-darken-1": "#D51F27",
      secondary: "#C3D1FF",
      "secondary-darken-1": "#A0A3BD",
      error: "#B00020",
      info: "#FFFFFF",
      success: "#4CAF50",
      warning: "#FB8C00",
    },
  },
};

const vuetify = createVuetify({
  theme: {
    defaultTheme: "flixlatinotheme",
    themes: {
      flixlatinotheme,
    },
  },
  components,
  directives,
});

const app = createApp(App);

const pinia = createPinia();

pinia.use(piniaPersist);
app.config.globalProperties.$bus = $bus;
app.config.globalProperties.$meta = $meta;

app.config.globalProperties.screenSizes = () => {
  return {
    width: window.screen.width,
    height: window.screen.height,
    offsetWidth: document.body.offsetWidth,
    offsetHeight: document.body.offsetHeight,
    availHeight: window.screen.availHeight,
    availWidth: window.screen.availWidth,
    prWidth: window.screen.width * window.devicePixelRatio,
    prHeight: window.screen.height * window.devicePixelRatio,
    prAvailHeight: window.screen.availHeight * window.devicePixelRatio,
    prAvailWidth: window.screen.availWidth * window.devicePixelRatio,
  };
};

app.config.globalProperties.isMobile = () => {
  const screenSizes = app.config.globalProperties.screenSizes();
  return (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) &&
    screenSizes.height <= 1366 &&
    screenSizes.width <= 1080
  );
};

app.config.globalProperties.isIphone = () => {
  return /iPhone/i.test(navigator.userAgent);
};

app.config.globalProperties.isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

app.config.globalProperties.isIosSafari = () => {
  return (
    /iPhone/i.test(navigator.userAgent) && /Safari/i.test(navigator.userAgent)
  );
};

function supportsWebp() {
  if (!self.createImageBitmap) return false;

  const webpData =
    "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
  const blob = fetch(webpData).then(function (r) {
    r.blob();
  });
  // @ts-ignore
  return createImageBitmap(blob).then(
    function () {
      return true;
    },
    function () {
      return false;
    }
  );
}
(function () {
  if (navigator.userAgent.includes("Lighthouse")) {
    app.config.globalProperties.supportsWebP = true;
    return;
  }
  if (supportsWebp()) {
    app.config.globalProperties.supportsWebP = true;
    document.body.classList.add("webp");
  } else {
    document.body.classList.add("no-webp");
    app.config.globalProperties.supportsWebP = false;
  }
})();

app.config.globalProperties.imgUrl = (url: String) => {
  if (app.config.globalProperties.supportsWebP) {
    if (url.endsWith(".jpg") || url.endsWith(".png")) {
      return url.replace(/\.(jpg|png)$/, ".webp");
    }
  }
  return url;
};

app.config.globalProperties.clevertap = clevertap;

const metaManager = createMetaManager();
app.config.globalProperties.$metaManager = metaManager;

// @ts-ignore
app.use(bugsnagVue);
app.use(InstantSearch);
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(VueObserveVisibility);
app.use(metaManager).use(vueMetaPlugin);
app.use(VueReCaptcha, {
  siteKey: "6LcHxrEqAAAAABBfFiyloUcQSkoSFpl4dYZvRnkO",
  loaderOptions: {
    autoHideBadge: true,
  },
});
app.mount("#app");
